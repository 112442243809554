html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ebebeb;
  color: #141414;
  transition: background-color 0.3s, color 0.3s;
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.content-box {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.dark-mode {
  background-color: #000000;
  color: #fff;
}

.dark-mode .content-box {
  background-color: #141414;
}

header {
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  height: 2.5rem;
}

@keyframes blink-caret {
  50% { border-color: transparent; }
}

.cursor {
  display: inline-block;
  border-right: 0.15em solid #141414;
  animation: blink-caret 0.75s step-end infinite;
}

.light-mode .cursor {
  border-right: 0.15em solid #141414;
}

.dark-mode .cursor {
  border-right: 0.15em solid #fff;
}

.mode-toggle {
  margin-bottom: 2.5rem;
  text-align: center;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.light-mode button {
  background-color: #141414;
  color: #fff;
}

.light-mode button:hover {
  background-color: #505050;
}

.dark-mode button {
  background-color: #fff;
  color: #000;
  border: none;
}

.dark-mode button:hover {
  background-color: #afafaf;
  color: #000;
}

.offer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
}

.input-container {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.dollar-sign {
  position: absolute;
  left: 10px;
  font-size: 1rem;
  color: #000;
  top: 50%;
  transform: translateY(-50%);
}

input {
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  font-size: 1rem;
  width: 200px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  text-align: left;
  color: #000;
  line-height: 1.5;
}

input::placeholder {
  text-align: left;
  color: #000;
}

.offer-container button {
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.dark-mode .dollar-sign,
.dark-mode input,
.dark-mode input::placeholder {
  color: #000;
}
